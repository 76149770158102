.icon-value-display {
	display: flex;
	gap: 8px;
	border-radius: 8px;
	.label {
		font-weight: 500;
		font-size: 11px;
		line-height: 15.4px;
		color: var(--color-grey-20);
	}
	.value {
		font-weight: 600;
		font-size: 14px;
		line-height: 19.6px;
		color: var(--color-grey-10);
	}
}
.icon-value-display-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	gap: 4px;
	.icon-value-display {
		padding: 8px 12px;
		background-color: var(--color-grey-90);
	}
}
.icon-value-display-wrapper-two-columns {
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	padding: 15px 20px;
	.icon-value-display {
		padding: 0;
		background-color: var(--color-grey-100);
	}
}
