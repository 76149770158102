.error-fallback {
	max-width: 800px;
	margin: auto;
	text-align: center;
	margin-top: 300px;
	display: flex;
	align-items: center;
	flex-direction: column;
	h1 {
		font-size: 20px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0.02em;
		text-align: center;
	}
	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 22.4px;
		text-align: center;
	}
	> * {
		margin-bottom: 20px;
	}
}
