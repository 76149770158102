@import '../../../scss/screen-size';

.account-form {
	display: grid;
	grid-template-columns: 1fr;
	max-width: 100%;
	gap: 10px;

	@include lg {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	[data-field-name='Name'] {
		grid-column: span 4;
	}

	[data-field-name='Business_Formal_Name__c'] {
		grid-column: span 4;
	}

	[data-field-name='Phone'] {
		grid-column: span 3;
	}

	[data-field-name='Time_Zone__c'] {
		grid-column: span 1;
	}

	.form-actions {
		grid-column: span 4;
	}
}
