:root {
	--color-primary-60: #f68065;
	--color-primary-50: #e65333;
	--color-primary-30: #bb4227;
	--color-secondary-90: #f6fcff;
	--color-secondary-80: #dff2ff;
	--color-secondary-50: #0293d2;
	--color-secondary-30: #067aac;
	--color-success-80: #e4faea;
	--color-success-60: #e4faea;
	--color-success-50: #0da432;
	--color-success-30: #277239;
	--color-danger-80: #ffebeb;
	--color-danger-50: #f52821;
	--color-danger-30: #d42721;
	--color-warning-80: #ffebc5;
	--color-warning-70: #ffe0aa;
	--color-warning-60: #f7c875;
	--color-warning-50: #ffad00;
	--color-warning-40: #d99300;
	--color-grey-100: #fff;
	--color-grey-90: #f9f9fe;
	--color-grey-80: #f4f5fe;
	--color-grey-70: #ebecf5;
	--color-grey-60: #dfe0ed;
	--color-grey-50: #cccee0;
	--color-grey-40: #9fa3c4;
	--color-grey-30: #7a7d9e;
	--color-grey-20: #3c4075;
	--color-grey-10: #20244f;
	--color-grey-00: #000;
	--color-box-shadow: #3061841a;
}
