/* Screen size breakpoints definition */

// Small tablets and large smartphones
$screen-sm: 480px;

// Small tablets
$screen-md: 675px;

// Tablets and small desktops
$screen-lg: 768px;

// Large tablets and desktops
$screen-xl: 1120px;

// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg}) {
		@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl}) {
		@content;
	}
}
