.toasts-wrapper {
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 9999;
	width: 500px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	.toast {
		box-shadow: 0 4px 4px 0 #00000029;
		border-radius: 8px;
		max-width: 350px;
		margin-top: 16px;
		min-width: 200px;
		position: relative;
		padding: 12px 12px 12px 16px;
		gap: var(--size-3);
		display: flex;
		&.error {
			color: var(--font-color);
			background-color: var(--color-danger-80);
		}
		&.success {
			background-color: var(--color-success-80);
		}
		.toast-icon {
			display: flex;
			align-items: center;
		}
		.toast-message {
			flex-grow: 1;
			display: flex;
			align-items: center;
		}
		.toast-close-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;
		}
		.toast-close-btn {
			border: none;
			background-color: transparent;
			font-size: 16px;
			cursor: pointer;
		}
	}
}
