.form-actions {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	padding: 8px 0;
	button {
		width: unset;
		padding: 10px 50px;
	}
}
