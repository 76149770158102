:root {
	--size-000: -0.5rem;
	--size-00: -0.25rem;
	--size-1: 0.25rem;
	--size-2: 0.5rem;
	--size-3: 1rem;
	--size-4: 1.25rem;
	--size-5: 1.5rem;
	--size-6: 1.75rem;
	--size-7: 2rem;
	--size-8: 3rem;
	--size-9: 4rem;
	--size-10: 5rem;
	--size-11: 7.5rem;
	--size-12: 10rem;
	--size-13: 15rem;
	--size-14: 20rem;
	--size-15: 30rem;
}
