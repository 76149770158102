.banner {
	border-radius: 5px;
	padding: 8px 15px;
	margin-bottom: 10px;
	font-weight: 400;
	font-size: 15px;
}

.error-banner {
	background-color: var(--color-danger-80);
	color: var(--color-danger-50);
}

.success-banner {
	margin-bottom: 5px;
	padding: 0 5px;
	color: var(--color-success-50);
}
