.btn {
	cursor: pointer;
	color: var(--color-grey-100);
	gap: 8px;
	font-weight: 600;
	font-size: var(--font-size-1);
	border: 1px solid;
	border-radius: 8px;
	letter-spacing: 0.0286em;
	background-color: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 250px;
	svg {
		display: block;
	}
}

.btn-medium {
	padding: 10px 16px;
	height: 40px;
	line-height: 19.6px;
	font-size: 14px;
}

.btn-large {
	padding: 13px 16px;
	height: 48px;
	line-height: 22.4px;
	font-size: 16px;

	svg {
		height: 24px;
		width: 24px;
	}
}

.primary {
	background-color: var(--color-primary-50);
	border-color: var(--color-primary-50);
	stroke: var(--color-grey-100);
}

.primary:hover,
.primary:focus-visible {
	background-color: var(--color-primary-30);
	border-color: var(--color-primary-30);
	color: var(--color-grey-100);
}

.secondary {
	background-color: var(--color-grey-100);
	border-color: var(--color-grey-50);
	color: var(--color-grey-20);
	stroke: var(--color-grey-20);
}

.secondary:hover,
.secondary:focus-visible {
	background-color: var(--color-grey-100);
	border-color: var(--color-grey-20);
}

[disabled],
[disabled]:hover,
[disabled]:focus-visible {
	background-color: var(--color-grey-30);
	border-color: var(--color-grey-30);
	stroke: var(--color-grey-30);
	pointer-events: none;
}

.full-width {
	width: 100%;
}
