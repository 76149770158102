/* stylelint-disable */
.date-range-calendar {
	.rdrDefinedRangesWrapper {
		width: 112px;
		border: none;
	}
	.rdrDay {
		padding: 0 8px;
	}
	.rdrDayNumber {
		font-size: 14px;
		line-height: 24px;
		font-weight: 500;
		letter-spacing: -0.5px;
	}
	.rdrEndEdge {
		background-color: var(--color-secondary-50);
		border-radius: 4px;
	}
	.rdrInRange {
		background-color: var(--color-secondary-90) !important;
	}
	.rdrInRange ~ span {
		span {
			color: var(--color-secondary-50) !important;
		}
	}
	.rdrStartEdge {
		background-color: var(--color-secondary-50);
		border-radius: 4px;
	}
	.rdrStaticRanges {
		button {
			border: none;
		}
		.rdrStaticRangeLabel {
			text-align: left;
			padding: 10px 8px;
			font-weight: 500;
			line-height: 24px;
			color: var(--color-grey-30);
		}
	}
	.rdrNextPrevButton {
		background-color: var(--color-grey-100);
		border: 1px solid #d8dae5;
	}
	.rdrMonthAndYearPickers {
		select {
			font-size: 18px;
		}
	}
}
/* stylelint-enable */
