.popper-overlay-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	background-color: var(--color-grey-100);
	padding: 7.5px 16px;
	border-radius: 50px;
	font-weight: 600;
	font-size: 12px;
	line-height: 16.8px;
	border: none;
	color: var(--color-grey-10);
	cursor: pointer;
}

.popper-tool-tip-hidden {
	visibility: hidden;
	pointer-events: none;
}

.popper-drop-down {
	background-color: var(--color-grey-100);
	text-align: center;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 500;
	line-height: 16.8px;
	z-index: 1;
	overflow: hidden;
	box-shadow: 0 20px 25px 0 #0b263991;
	padding: 10px 20px;
	a {
		transition: none;
	}
}
