:root {
	--font-size-7: 3.815rem;
	--font-size-6: 3.052rem;
	--font-size-5: 2.441rem;
	--font-size-4: 1.953rem;
	--font-size-3: 1.563rem;
	--font-size-2: 1.25rem;
	--font-size-1: 1rem;
	--font-size-0: 0.8rem;
	--font-size-00: 0.64rem;
	--font-size-000: 0.512rem;
	--font-size-fluid-0: clamp(0.75rem, 2vw, 1rem);
	--font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
	--font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
	--font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);
}
