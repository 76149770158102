@import '../../../scss/screen-size';

.panel-container {
	border-radius: 15px;
	overflow: hidden;
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0 5px 15px 0 #0000000d;
	max-height: 100%;
	&.data-table-wrapper {
		border-radius: 0;
		background: transparent;
		box-shadow: none;
		@include lg {
			background-color: #fff;
			border-radius: 15px;
			box-shadow: 0 5px 15px 0 #0000000d;
		}
	}
	.panel-header {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0 24px;
		padding: 20px 0 10px;
		.input-container {
			margin: 0;
		}
		.mobile-drop-trigger {
			display: block;
			position: absolute;
			right: 20px;
			transform: rotate(90deg);
			@include lg {
				display: none;
			}
		}
	}
	.panel-body {
		display: flex;
		width: 100%;
		flex-grow: 1;
		min-height: 0;
		flex-direction: column;
		padding: 15px 24px;
	}
	&.mobile-drop {
		.panel-body {
			display: none;
		}
		&.expand {
			.panel-body {
				display: flex;
			}
			.mobile-drop-trigger {
				transform: rotate(-90deg);
			}
		}
		@include lg {
			.panel-body {
				display: flex;
			}
		}
	}
	&.map-panel-wrapper {
		height: 400px;
	}
	.panel-header-border {
		border-bottom: 1px solid var(--color-grey-70);
	}
	.panel-header-title {
		font-size: 16px;
		line-height: 22.4px;
		font-weight: 600;
		font-family: Montserrat, sans-serif;
		display: flex;
		gap: 10px;
		align-items: center;
	}
	.panel-controls {
		justify-content: flex-end;
		align-items: flex-end;
		display: flex;
		gap: var(--size-2);
		flex-grow: 1;
	}
	.table {
		border-top: none !important;
	}
	.title-button {
		display: flex;
		gap: 10px;
		align-items: center;
	}
}

.panel-row {
	padding: 20px 0;
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
}

.panel-no-background {
	background-color: transparent;
	box-shadow: none;
}

.center-tabs {
	justify-content: center;
	overflow-y: scroll;
}

.panel-center-contents {
	justify-content: center;
	h2 {
		justify-content: center;
		flex-direction: column;
	}
}
