@import '../../../scss//screen-size';

footer {
	display: block;
	height: 80px;
	border-top: 1px solid var(--color-grey-70);
	background: var(--color-grey-100);
	box-shadow: 0 -3px 12px 0 rgb(48 97 132 / 6%);
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	@include lg {
		display: none;
	}

	.links-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 40px;
		height: 80px;
		.nav-link {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			font-family: var(--font-montserrat);
			font-size: 10px;
			font-weight: 500;
			text-transform: uppercase;
			.icon-container {
				width: 20px;
				overflow: hidden;
				svg {
					height: 20px;
					width: auto;
					position: relative;
				}
			}
			label {
				cursor: pointer;
			}
			&.active {
				font-weight: 600;
				color: var(--color-secondary-50);
				svg {
					stroke: var(--color-secondary-50);
				}
			}
		}
	}
}
