@import 'scss/colors';
@import 'scss/spacing';
@import 'scss/font-size';
@import 'scss/screen-size';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none !important;
}

html {
	text-rendering: optimizelegibility !important;
	-webkit-font-smoothing: antialiased !important;
	text-size-adjust: 100%;
}

html,
body {
	min-height: 100%;
	width: 100%;
}

body {
	min-height: 100%;
	width: 100%;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
	color: var(--color-grey-10);
	&.fixed {
		position: static;
		@include lg {
			position: fixed;
		}
	}
	&.has-back-trigger {
		header {
			visibility: hidden;
		}
		@include lg {
			header {
				visibility: visible;
			}
		}
	}
	&.flyout-fixed {
		position: fixed;
	}
}

#root {
	background:
		url('/svgs/background-waves.svg') no-repeat,
		linear-gradient(180deg, #c9ecff, #f9f9fe);
	background-size: cover;
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;
	background-color: #f9f9fb;
}

.page-loading-wrap {
	width: 100%;
	height: 100%;
	padding-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	margin: 0;
	padding: 0;
	border: 0;
	background: transparent;
}

.wrap {
	min-height: calc(100vh - 107px);
}

.main-container {
	padding: 20px 10px 100px;
	margin: 0 auto;
	width: calc(min(100%, 1500px));
}

@include lg {
	.main-container {
		padding: 20px;
		margin: 0 auto;
		width: calc(min(100%, 1500px));
	}
}

a {
	text-decoration: none;
}

/* stylelint-disable */
#AcceptUIContainer {
	width: calc(min(500px, 100%)) !important;
}
/* stylelint-enable */
