@import '../../../scss/screen-size';

.modal-body {
	padding: 20px;
	max-height: 80vh;
	overflow-y: auto;
	form {
		margin-top: 0;
	}
}
.modal-close-btn {
	position: absolute;
	right: 20px;
	top: 10px;
	cursor: pointer;
	background: transparent;
	border: none;
	font-size: 30px;
	font-weight: 400;
	z-index: 100;
}

.modal-header {
	border-bottom: 1px solid var(--color-grey-70);
	padding: 20px;
	position: relative;
	h2 {
		font-size: 16px;
	}
}
/* stylelint-disable */
.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
	background-color: rgb(0, 0, 0);
	display: flex;
	justify-content: center;
	z-index: 10;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.Modal {
	inset: 50% auto auto 50%;
	padding: 0;
	transform: translate(-50%, -50%);
	position: absolute;
	border: 1px solid rgb(204, 204, 204);
	background: rgb(255, 255, 255);
	overflow: auto;
	border-radius: 15px;
	width: 100%;
	max-width: 90vw;
	margin: 0;
	@include md {
		width: unset;
	}
}
/* stylelint-enable */
.form-actions {
	display: flex;
	padding: 25px 10px 0;
	justify-content: flex-end;
}
