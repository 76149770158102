.input-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 8px;
	text-align: left;
	margin-bottom: 8px;

	.label {
		font-weight: 500;
		font-size: 14px;
		line-height: 19.6px;
	}
	textarea {
		padding: 8px;
	}
	input,
	textarea,
	select {
		border: 1px solid;
		border-color: var(--color-grey-50);
		background-color: var(--color-grey-100);
		border-radius: 8px;
		font-weight: 500;
		padding: 10px 12px;
		font-size: 14px;
		line-height: 19.8px;
		width: 100%;
		&::placeholder {
			color: var(--color-grey-30);
		}
		&:active,
		&:focus {
			border-color: var(--color-grey-10);
		}
		&:disabled {
			cursor: not-allowed;
		}
	}
	input[role='combobox'] {
		height: 100%;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		appearance: textfield;
	}

	input[type='checkbox'] {
		width: unset;
		height: unset;
		display: inline;
		margin-right: 5px;
	}
	.error {
		color: var(--color-danger-50);
		line-height: 16.8px;
		font-size: 12px;
		font-weight: 500;
		text-align: left;
	}
}
