@import '../../../scss/screen-size';

header {
	z-index: 3;
	ul {
		display: flex;
		list-style: none;
		gap: 8px;
		li {
			a {
				display: flex;
				padding: 8px 12px;
				gap: 8px;
				align-items: center;
				border-radius: 8px;
				color: var(--color-grey-10);
				font-weight: 500;
				font-size: 14px;
				line-height: 19.8px;
				&.active {
					background-color: var(--color-grey-100);
					color: var(--color-secondary-50);
					box-shadow: 0 4px 12px 0 #3061841a;
					svg {
						stroke: var(--color-secondary-50);
					}
				}
			}
			&.nav-link {
				display: none;
				@include lg {
					display: inline;
				}
			}
		}
	}
	.main {
		display: flex;
		padding: 15px 20px;
		position: relative;
		align-items: center;
		width: calc(min(100%, 1500px));
		margin: auto;
		.main-logo-container {
			img,
			svg {
				width: 96px;
			}
		}
	}
	button {
		height: 30px;
		width: 30px;
		border-radius: 50px;
		border: 1px solid var(--color-grey-100);
		background-color: var(--color-grey-70);
		font-weight: 600;
		cursor: pointer;
		margin-top: 3px;
		margin-left: 5px;
	}
	.drop-down-nav {
		flex-direction: column;
		a {
			display: flex;
			gap: 12px;
			padding: 6px 8px;
			font-weight: 500;
			font-size: 14px;
			line-height: 16.8px;
			flex-grow: 1;
			&.active {
				background-color: unset;
				box-shadow: unset;
			}
		}
	}
}

.popper-dropdown-hidden {
	visibility: hidden;
	pointer-events: none;
}
