@import '../../../scss/screen-size';

.account-detail-panel {
	.panel-header {
		gap: 5px;
		flex-direction: column;
		@include lg {
			flex-direction: row;
		}

		.avatar-container {
			display: flex;
			top: unset;
			left: unset;
			height: unset;
			width: unset;
			.name {
				height: 64px;
				width: 64px;
				font-size: 28.8px;
				font-weight: 700;
				line-height: 40.32px;
				text-align: center;
			}
			@include lg {
				display: none;
			}
		}
		.account-label {
			font-size: 12px;
			font-weight: 500;
			line-height: 16.8px;
			text-align: center;
			display: block;
			@include lg {
				display: none;
			}
		}
	}
	.view-more {
		margin-top: 10px;
		display: flex;
		cursor: pointer;
		color: var(--color-secondary-50);
		font-size: 12px;
		font-weight: 500;
		line-height: 16.8px;
		text-align: center;
		background-color: transparent;
		border: none;
		align-items: center;
		gap: 5px;
		svg {
			path {
				stroke: var(--color-secondary-50) !important;
			}
		}
		@include lg {
			display: none;
		}
	}
	.panel-body {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.hide-body {
		display: none !important;
	}
	@include lg {
		.hide-body {
			display: flex !important;
		}
	}
}
