.main-logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	span {
		text-transform: capitalize;
		font-size: 26px;
		font-weight: 300;
		transition: all 0.25s ease;
		position: relative;
		top: 1px;
		&.divider {
			height: 30px;
			width: 2px;
			margin: 0 5px;
			top: 0;
		}
	}
	&:hover,
	&:focus,
	&:active {
		span {
			color: #fff;
		}
	}
}
